<template>
  <div
    v-if="upsell.has && itemsAreAvailableInLocale"
    class="cart-menu__upselling"
  >
    <div class="cart-menu__upselling__top d-flex justify-space-between">
      <p
        class="text-label text-md-small text-uppercase text-md-none w-600 mb-0"
      >
        {{
          $t(
            countItems === 0
              ? 'cart.upsell.title.empty'
              : upsell.group && upsell.group.type === UPSELLING_TYPES.PRODUCTS
              ? `cart.upsell.title.${groupName}`
              : 'cart.upsell.title.link'
          )
        }}
      </p>

      <nav
        v-if="upsell.list.length > 1 && countItems > 0"
        class="cart-menu__upselling__nav d-flex"
      >
        <primary-button
          :text="$t('prev')"
          text-visually-hidden
          type="button"
          aspect="transparent"
          with-arrow
          arrow-position="left"
          arrow-direction="left"
          @click="prev"
        />

        <span class="text-label">
          {{ currentUpsellingItem }} / {{ upsell.list.length }}
        </span>

        <primary-button
          :text="$t('next')"
          text-visually-hidden
          type="button"
          aspect="transparent"
          with-arrow
          arrow-direction="right"
          @click="next"
        />
      </nav>
    </div>

    <div
      ref="upsellItems"
      class="cart-menu__upselling__elements ml-1 ml-md-1-5 mr-md-1-5 mb-0-8"
      :class="{ 'only-one': upsell.list.length === 1 }"
      @mousedown="onMouseDown"
      @touchstart="onMouseDown"
    >
      <cart-up-sell-item
        v-for="(item, index) in upsell.list"
        :id="`upsellItem${index}`"
        ref="upsellItem"
        :key="item.id"
        :item="item"
        :loading="isLoadingUpselling"
        @click.native="
          index + 1 !== currentUpsellingItem ? goTo(index + 1) : ''
        "
      ></cart-up-sell-item>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CartUpSellItem from '@/components/cart/CartUpSellItem'
import PrimaryButton from '@/components/buttons/Primary'

import { UPSELLING_TYPES, UPSELLING_TRADS } from '@/const'

export default {
  name: 'CartUpSellBlock',
  components: {
    CartUpSellItem,
    PrimaryButton,
  },
  data: () => ({
    currentUpsellingItem: 1,
    fromMove: false,
    posX: null,
    scrollLeft: null,
    UPSELLING_TYPES,
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      countItems: 'cart/countItems',
      upsell: 'cart/upsell',
      isLoadingUpselling: 'cart/isLoadingUpselling',
    }),
    groupName() {
      if (this.upsell.group && UPSELLING_TRADS.includes(this.upsell.group.name))
        return this.upsell.group.name

      return 'default'
    },
    itemsAreAvailableInLocale() {
      return this.upsell.list.some(
        (i) => i.slug[this.$i18n.locale.substr(0, 2)]
      )
    },
  },

  watch: {
    async currentUpsellingItem() {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)
      const { ScrollToPlugin } = await import(
        'gsap/ScrollToPlugin' /* webpackChunkName: 'gsap_scroll_to_plugin' */
      )

      gsap.registerPlugin(ScrollToPlugin)

      gsap.to(this.$refs.upsellItems, {
        scrollTo: `#upsellItem${this.currentUpsellingItem - 1}`,
        duration: 0.3,
        ease: 'ease.in',
      })
    },
  },
  methods: {
    goTo(index) {
      if (!this.fromMove) {
        this.currentUpsellingItem = index
      }
    },
    prev() {
      if (this.currentUpsellingItem === 1) {
        this.currentUpsellingItem = this.upsell.list.length
        return
      }
      this.currentUpsellingItem--
    },
    next() {
      if (this.currentUpsellingItem === this.upsell.list.length) {
        this.currentUpsellingItem = 1
        return
      }
      this.currentUpsellingItem++
    },
    onMouseDown(e) {
      if (this.countItems === 0) {
        return
      }
      e.preventDefault()
      this.fromMove = false
      if (e.touches) {
        this.posX = e.touches[0].clientX
      } else {
        this.posX = e.clientX
      }

      this.scrollLeft = this.$refs.upsellItems.scrollLeft

      this.$refs.upsellItems.style.cursor = 'grabbing'

      document.addEventListener('mousemove', this.onMouseMove, true)
      document.addEventListener('touchmove', this.onMouseMove, true)
      document.addEventListener('mouseup', this.onMouseUp, true)
      document.addEventListener('touchend', this.onMouseUp, true)
    },
    onMouseMove(e) {
      if (this.countItems === 0) {
        return
      }
      let diffX
      if (e.touches) {
        diffX = this.posX - e.changedTouches[0].clientX
      } else {
        diffX = this.posX - e.clientX
      }

      if (this.$refs.upsellItems) {
        this.$refs.upsellItems.classList.add('moving')
        this.$refs.upsellItems.style.cursor = 'grabbing'
        this.$refs.upsellItems.scrollLeft = this.scrollLeft + diffX
      }
    },
    onMouseUp(e) {
      if (this.countItems === 0) {
        return
      }

      e.stopPropagation()
      let diffX

      if (e.touches) {
        diffX = this.posX - e.changedTouches[0].clientX
      } else {
        diffX = this.posX - e.clientX
      }

      const currentUpsellingItem = this.$refs.upsellItems.querySelector(
        `#upsellItem${this.currentUpsellingItem - 1}`
      )

      if (diffX < -30) {
        this.fromMove = true
        this.prev()
      } else if (diffX > 30) {
        this.fromMove = true
        this.next()
      } else if (
        currentUpsellingItem &&
        currentUpsellingItem.contains(e.target)
      ) {
        currentUpsellingItem.__vue__.click(e)
      }

      this.$refs.upsellItems.style.removeProperty('cursor')

      document.removeEventListener('mousemove', this.onMouseMove, true)
      document.removeEventListener('touchmove', this.onMouseMove, true)
      document.removeEventListener('mouseup', this.onMouseUp, true)
      document.removeEventListener('touchend', this.onMouseUp, true)

      this.$nextTick().then((_) => {
        this.$refs.upsellItems.classList.remove('moving')
      })

      return false
    },
  },
}
</script>

<style lang="scss">
.cart-menu__upselling {
  border-top: 1px solid var(--secondary-color-3);

  &__top {
    padding: rem($spacing * 0.6) rem($spacing) rem($spacing * 0.6 - 1px);

    @include mq($until: tablet) {
      line-height: rem(24px);
    }

    @include mq($from: tablet) {
      padding: rem($spacing) rem($spacing * 1.5);
    }
  }

  &__nav {
    > span {
      align-self: center;
    }

    .action {
      padding: 0 var(--spacing);
      height: auto;
    }
  }

  &__elements {
    display: flex;
    overflow: auto;
    scrollbar-width: none;
    cursor: grab;

    &::-webkit-scrollbar {
      display: none;
    }

    .cart-item--upsell {
      min-width: calc(100% - var(--spacing) * 2);
      margin-right: calc(var(--spacing) * 0.6);
      padding: calc(var(--spacing) * 0.6);

      &:last-child {
        margin-right: var(--spacing);
      }

      @include mq($from: tablet) {
        min-width: calc(100% - var(--spacing) * 3);
        margin-right: var(--spacing);
        padding: var(--spacing);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.only-one {
      .cart-item--upsell {
        min-width: calc(100% - var(--spacing));

        @include mq($from: tablet) {
          min-width: 100%;
        }
      }
    }
  }

  .cart-empty & {
    .cart-menu__upselling__elements {
      display: block;

      .cart-item--upsell {
        margin: 0 0 var(--spacing);
      }
    }
  }
}
</style>
