<template>
  <div id="header">
    <header
      ref="header"
      class="header d-flex align-center align-md-end"
      :class="{
        'header--fixed': isFixed,
        'no-animate': noAnimate,
      }"
      data-cy-header
    >
      <div id="header-row" class="d-flex flex-grow-1">
        <div class="header__left d-flex">
          <h1 v-if="isHome" class="header__logo mb-0" data-cy-logo>
            <icon-logo />
            <span class="visually-hidden"
              >Asphalte -
              {{ $t(`context.${$route.params.context || 'h'}`) }}</span
            >
          </h1>
          <nuxt-link
            v-else
            :to="localePath(`/${$route.params.context || 'h'}`)"
            class="header__logo header__logo--link"
            data-cy-logo
          >
            <icon-logo />
            <span class="visually-hidden">Asphalte</span>
          </nuxt-link>

          <div
            v-if="$i18n.locale === 'fr-FR'"
            class="header__nav header__nav--context d-none d-md-flex align-end ml-1"
          >
            <nuxt-link
              :to="localePath('/h')"
              class="header__nav__link link--underline text-secondary text-uppercase"
              :class="{ 'header__nav__link--active': currentContext === 'h' }"
              @click.native="switchContext('h')"
            >
              <span>{{ $t('context.h') }}</span>
            </nuxt-link>
            <nuxt-link
              :to="localePath('/f')"
              class="header__nav__link link--underline text-secondary text-uppercase"
              :class="{ 'header__nav__link--active': currentContext === 'f' }"
              @click.native="switchContext('f')"
            >
              <span>{{ $t('context.f') }}</span>
            </nuxt-link>
          </div>
        </div>

        <nav-header :menu="menu" :eshop-menu="eshopMenu" :skeleton="skeleton" />

        <div class="header__right d-flex justify-end">
          <i18n-switcher
            v-if="$route.params.context && $route.params.context !== 'f'"
            class="d-flex"
          />

          <a
            href="#"
            class="header__link header__notif d-flex align-end wisp link--underline"
          >
            <span class="header__link__icon d-block">
              <icon name="notif" class="header__icon" />
            </span>
            <span class="text-label text-uppercase w-600">
              {{ $t('link.infos') }}
            </span>
          </a>

          <nuxt-link
            :to="$contextPath('/account')"
            class="header__link header__user d-flex align-end link--underline"
          >
            <span class="header__link__icon d-block">
              <icon name="user" class="header__icon d-block" />
            </span>
            <span class="text-label text-uppercase w-600">
              {{ $t(authenticated ? 'link.account' : 'link.sign-in') }}
            </span>
          </nuxt-link>

          <a
            id="linkOpenCart"
            href="#cart"
            class="header__link header__cart d-flex align-center align-md-end link--underline"
            @click.prevent="$emit('open', 'cart', $event)"
          >
            <span class="header__link__icon d-block">
              <icon name="cart" class="header__icon d-block" />
              <span v-if="countItems > 0" class="header__cart__number">
                {{ countItems }}
              </span>
            </span>
            <span class="text-label text-uppercase w-600">
              {{ $t('link.cart') }}
            </span>
          </a>

          <a
            href="#menu"
            class="header__open-menu"
            @click.prevent="$emit('open', 'menu', $event)"
          >
            <div class="header__burger">
              <span class="line line--top"></span>
              <span class="line line--middle"></span>
              <span class="line line--bottom"></span>
            </div>

            <strong class="text-label text-uppercase w-600">
              {{ $t('link.menu') }}
            </strong>

            <span class="wisp_extra_count wisp-mobile"></span>
          </a>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'

import IconLogo from '@/components/icons/Logo'
import Icon from '@/components/icons/Icon'
import I18nSwitcher from '@/components/i18n/Switcher'
import NavHeader from '@/components/nav/NavHeader'

export default {
  name: 'HeaderDefault',
  components: {
    IconLogo,
    Icon,
    I18nSwitcher,
    NavHeader,
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    header: { type: Object, required: true },
    skeleton: { type: Boolean, default: false },
  },
  data() {
    return {
      lastScrollTop: 0,
      scrollDirection: 'down',
      lastDirectionChange: 0,
      noAnimate: false,
      isFixed: false,
      isVisible: false,
      isTransparent: false,
      anim: null,
    }
  },
  computed: {
    ...mapGetters({
      countItems: 'cart/countItems',
      isAutoScroll: 'behavior/scroll',
      authenticated: 'auth/authenticated',
      allowHeaderFixing: 'behavior/fixHeader',
    }),
    menu() {
      if (this.$route.params.context && this.$route.params.context === 'f') {
        return this.header.data.menu_f
      }

      return this.header.data.menu_h
    },
    eshopMenu() {
      if (this.$route.params.context && this.$route.params.context === 'f') {
        return this.header.data.shop_links_f
      }

      return this.header.data.shop_links_h
    },
    isHome() {
      return this.$nuxt.$route.name
        ? this.$nuxt.$route.name.includes('index') ||
            this.$nuxt.$route.name.startsWith('context___')
        : false
    },
    currentContext() {
      if (this.$route.params.context) {
        return this.$route.params.context
      }

      return 'h'
    },
  },
  mounted() {
    this.initScroll()
    this.initAnim()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onWindowScroll)
  },
  methods: {
    ...mapActions({
      closeOtherMenus: 'behavior/closeObjects',
    }),
    initScroll() {
      window.addEventListener('scroll', this.onWindowScroll)
    },
    async initAnim() {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      this.anim = gsap.timeline({
        paused: true,
        onComplete: () => {
          document.body.classList.add('header-visible')
          this.$refs.header.classList.add('visible')
        },
        onReverseComplete: () => {
          document.body.classList.remove('header-visible')
          this.$refs.header.classList.remove('visible')
        },
      })
      this.anim.to(this.$refs.header, {
        duration: 0.4,
        y: '0%',
        ease: 'power1.out',
      })
    },
    onWindowScroll() {
      const currentScrollTop = document.documentElement.scrollTop
      if (currentScrollTop > 200 && !this.isFixed && this.allowHeaderFixing) {
        this.isFixed = true
        this.isVisible = false
      } else if (currentScrollTop < 200 && this.isFixed) {
        this.isFixed = false
        document.body.classList.remove('header-visible')
        if (this.anim) {
          this.anim.pause().progress(0)
        }
        this.$refs.header.style = ''
      }

      if (!this.isAutoScroll && this.allowHeaderFixing) {
        if (currentScrollTop > this.lastScrollTop) {
          if (this.scrollDirection === 'up') {
            this.lastDirectionChange = currentScrollTop
          }

          this.scrollDirection = 'down'
        } else {
          if (this.scrollDirection === 'down') {
            this.lastDirectionChange = currentScrollTop
          }

          this.scrollDirection = 'up'
        }

        if (this.isFixed) {
          if (
            Math.abs(this.lastDirectionChange - currentScrollTop) > 200 &&
            this.scrollDirection === 'up' &&
            !this.isVisible
          ) {
            this.isVisible = true
            this.showHeader()
          } else if (this.scrollDirection === 'down' && this.isVisible) {
            this.isVisible = false
            this.hideHeader()
            this.closeOtherMenus()
          }
        }

        this.lastScrollTop = currentScrollTop
      }
    },
    showHeader() {
      if (this.anim) {
        this.anim.play()
      }
    },
    hideHeader() {
      if (this.anim) {
        this.anim.reverse()
      }
    },
    switchContext(context) {
      this.$emit('switch_context', context)
      this.$track.click('switch_context', { context })
      this.$track.context(context)
    },
  },
}
</script>

<style lang="scss">
#header {
  min-height: var(--header-height);

  .header-transparent & {
    min-height: 0;
  }

  @include mq($until: tablet) {
    .header-transparent-mobile & {
      min-height: 0;
    }
  }
}

@mixin header-transparent() {
  width: 100%;
  background: transparent;

  .header__burger .line {
    background: var(--tertiary-color);
  }

  &:not(.header--fixed) {
    position: absolute;
    top: 0;
    left: 0;
    border-color: transparent;
    transition: background 0.7s var(--asphalte-animation-function);

    .header__link,
    .header__nav__link,
    .lang-switcher__link a,
    .header__open-menu {
      color: var(--tertiary-color);
    }

    .header__nav__link--highlight {
      color: var(--secondary-color);

      span::after {
        background: var(--tertiary-color);
      }
    }

    .header__nav__submenu {
      .header__nav__link {
        color: var(--secondary-color);
      }
    }

    @include mq($from: tablet) {
      &:hover {
        background: var(--tertiary-color);

        .header__link,
        .header__nav__link,
        .lang-switcher__link a,
        .header__open-menu {
          color: var(--secondary-color);
        }

        .header__nav__link--highlight {
          span::after {
            background: var(--secondary-color);
          }
        }

        .logo {
          color: var(--secondary-color);
        }
      }
    }

    .header--dark & {
      .header__burger .line {
        background-color: var(--secondary-color);
      }

      .header__link,
      .header__nav__link,
      .lang-switcher__link a,
      .header__open-menu {
        color: var(--secondary-color);
      }

      .logo {
        color: var(--secondary-color);
      }
    }
  }

  .logo {
    color: var(--tertiary-color);
  }

  &.header--fixed {
    background-color: var(--tertiary-color);

    .header__link,
    .header__nav__link {
      color: var(--text-body-color);
    }

    .header__nav__link--highlight {
      span::after {
        background: var(--text-body-color);
      }
    }

    .header__burger .line {
      background: black;
    }

    .logo {
      color: var(--secondary-color);
    }
  }
}

.header {
  background: var(--tertiary-color);
  border-bottom: 1px solid var(--secondary-color-3);
  position: relative;
  z-index: 2000;
  transition: transform 0.3s;
  will-change: transform;
  min-height: var(--header-height);

  @include mq($until: tablet) {
    transition: transform 0.4s cubic-bezier(0.46, 0.01, 0.32, 1);

    &.cart-open {
      transform: translateX(#{rem(-480px)});

      @include mq($until: 500px) {
        transform: translateX(-95%);
      }
    }
  }

  @include mq($from: landscape) {
    padding: 0 calc(var(--spacing) * 1.4);
  }

  @include mq($from: 1400px) {
    padding: 0 calc(var(--spacing) * 2);
  }

  a {
    color: var(--secondary-color);
  }

  .row {
    min-height: rem(60px);

    @include mq($from: tablet) {
      min-height: rem(75px);
    }
  }

  .lang-switcher {
    @include mq($until: 1024px) {
      display: none;
    }

    &__link {
      display: flex;

      a {
        padding: 0 calc(var(--spacing) * 0.6) calc(var(--spacing) * 1.4)
          calc(var(--spacing) * 1.8);
        line-height: 1;
        display: flex;
        align-items: flex-end;

        @include mq($from: 1400px) {
          padding: 0 var(--spacing) rem(20px) calc(var(--spacing) * 2.2);
        }
      }
    }

    &__nav {
      margin-top: rem(1px);
      z-index: -1;

      a {
        min-width: rem(180px);
        text-align: center;
        text-transform: uppercase;
        display: block;
        padding: calc(var(--spacing) * 0.75) var(--spacing);
        border: solid var(--secondary-color-3);
        border-width: 0 rem(1px);
        background: var(--tertiary-color);
        letter-spacing: 0.08em;
        transition: all 0.3s;
      }

      > div {
        &:first-child {
          a {
            padding-top: rem($spacing);
          }
        }

        &:last-child {
          a {
            border-bottom-width: rem(1px);
            padding-bottom: rem($spacing);
          }
        }
      }
    }
  }

  .wisp_count {
    font-size: rem(8px);
  }

  &__logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @include mq($until: tablet) {
      flex-grow: 1;
    }

    @include mq($from: tablet) {
      padding: calc(var(--spacing) * 1.4) 0;
    }

    .logo {
      width: auto;
      color: var(--secondary-color);
      transition: all 0.3s;
      height: rem(30px);
      flex-shrink: 0;

      @include mq($until: tablet) {
        margin: 0 var(--spacing);
      }

      @include mq($from: large) {
        height: rem(35px);
      }
    }
  }

  &__open-menu {
    position: relative;
    padding: 0 var(--spacing) 0 calc(var(--spacing) * 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mq($from: 1025px) {
      display: none;
    }

    .wisp_extra_count {
      position: static;
    }

    .wisp_count {
      top: rem(5px) !important;
      right: rem(5px) !important;
    }
  }

  &__burger {
    position: relative;
    width: rem(28px);
    height: rem(28px);

    .line {
      position: absolute;
      width: 100%;
      height: rem(2px);
      background: var(--secondary-color);
      transition: all 0.3s;
      transform-origin: 50% 50%;
      border-radius: 4px;

      &--top {
        top: rem(4px);
      }

      &--middle {
        top: 50%;
        transform: translateY(-50%);
      }

      &--bottom {
        bottom: rem(4px);
      }
    }
  }

  &__cart {
    padding: 0 calc(var(--spacing) * 0.8);

    > span {
      position: relative;
    }

    .header__cart__number {
      --size: #{rem(15px)};

      position: absolute;
      right: rem(-6px);
      top: rem(-1px);
      font-size: rem(8px);
      line-height: var(--size);
      text-align: center;
      font-weight: 700;
      background: var(--sun-color);
      display: block;
      width: var(--size);
      height: var(--size);
      color: var(--tertiary-color);
      border-radius: 50%;

      &::after {
        content: none;
      }
    }

    .header__icon {
      @include mq($until: tablet) {
        width: rem(22px);
        height: rem(24px);
      }
    }
  }

  .header__icon {
    display: block;
  }

  &__link {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing);

    @include mq($from: tablet) {
      justify-content: flex-end;
      padding: 0 calc(var(--spacing) * 0.6) var(--spacing);
    }

    @include mq($from: 1400px) {
      padding: 0 var(--spacing) var(--spacing);
    }

    span {
      &::after {
        bottom: rem(-2px);
      }
    }

    .wisp_count {
      top: 45% !important;
      right: 50% !important;
      transform: translate(50%, -50%);
      margin: rem(-6px) rem(-8px) 0 0;
    }

    strong {
      padding-top: calc(var(--spacing) * 0.2);
      white-space: nowrap;
    }

    .header__link__icon {
      padding-bottom: calc(var(--spacing) * 0.2);

      &::after {
        content: none;
      }
    }
  }

  &__left,
  &__right {
    flex-shrink: 0;
  }

  &__left {
    @include mq($until: 1025px) {
      margin-right: auto;
    }
  }

  &__notif,
  &__user {
    @include mq($until: 1024px) {
      display: none;
    }
  }

  &--fixed {
    position: fixed;
    width: 100%;
    transform: translateY(-100%);
    transition: none;
    top: 0;

    &.visible {
      transform: translateY(0);

      &.menu-open {
        transform: translate(#{rem(280px)}, 0);
      }
    }

    .row {
      min-height: rem(50px);

      @include mq($from: tablet) {
        min-height: rem(58px);
      }
    }

    .header__open-menu .wisp_count {
      top: rem(12px) !important;
    }
  }

  .header-transparent & {
    @include header-transparent;
  }

  @include mq($until: tablet) {
    .header-transparent-mobile & {
      @include header-transparent;
    }
  }
}
</style>
